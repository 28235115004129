export enum LegacyHeaderVariant {
  BusinessAccounts = 'Business Accounts',
  Ecg = 'Ecg',
  Eeg = 'Eeg',
  Eng = 'Eng',
  ESg = 'ESg',
  ESol = 'ESol',
  Gewerbekunden = 'Gewerbekunden',
  GWK = 'GWK',
  Job = 'Job',
  KeyAccounts = 'Key Accounts',
  Kommunen = 'Kommunen',
  Konzern = 'Konzern',
  MeinHGas = 'MeinHGas',
  Privatkunde = 'Privatkunde',
}

export const LEGACY_HEADER_LOGO_URLS: Record<LegacyHeaderVariant, string> = {
  [LegacyHeaderVariant.BusinessAccounts]: '/firmenkunden/business-accounts',
  [LegacyHeaderVariant.Ecg]: '/',
  [LegacyHeaderVariant.Eeg]: '/',
  [LegacyHeaderVariant.Eng]: '/',
  [LegacyHeaderVariant.ESg]: '/',
  [LegacyHeaderVariant.ESol]: '/',
  [LegacyHeaderVariant.Gewerbekunden]: '/firmenkunden/gewerbekunden',
  [LegacyHeaderVariant.GWK]: '/geschaeftskunden',
  [LegacyHeaderVariant.Job]: '/',
  [LegacyHeaderVariant.KeyAccounts]: '/firmenkunden/key-accounts',
  [LegacyHeaderVariant.Kommunen]: '/kommunen',
  [LegacyHeaderVariant.Konzern]: '/konzern',
  [LegacyHeaderVariant.MeinHGas]: '/',
  [LegacyHeaderVariant.Privatkunde]: '/',
};
