import styles from './HeatProductPageHeatPumpImage.module.scss';
import type { HeatProductPageHeatPumpImageProps } from './types';

const HeatProductPageHeatPumpImage = ({
  ...props
}: HeatProductPageHeatPumpImageProps) => {
  return (
    <div className={styles.base}>
      <svg
        viewBox="0 0 1740 684"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={styles.image}
        {...props}
      >
        <rect x="2" width="1738" height="684" fill="white" />
        <rect
          width="1738"
          height="684"
          transform="translate(2)"
          fill="url(#paint0_linear_2654_2615)"
        />
        <path
          d="M2 638H1740V680C1740 682.209 1738.21 684 1736 684H6.00003C3.79089 684 2 682.209 2 680V638Z"
          fill="#EBCCEB"
        />
        <path d="M2 638L1740 638" stroke="#D699D6" strokeWidth="4" />
        <rect
          x="68"
          y="258"
          width="68"
          height="32"
          rx="4"
          fill="white"
          stroke="#F5E6F5"
          strokeWidth="4"
        />
        <rect
          x="697"
          y="468"
          width="68"
          height="32"
          rx="4"
          fill="white"
          stroke="#F5E6F5"
          strokeWidth="4"
        />
        <rect
          x="318"
          y="560"
          width="68"
          height="32"
          rx="4"
          fill="white"
          stroke="#F5E6F5"
          strokeWidth="4"
        />
        <rect
          x="109"
          y="302"
          width="68"
          height="32"
          rx="4"
          fill="white"
          stroke="#F5E6F5"
          strokeWidth="4"
        />
        <rect
          x="738"
          y="512"
          width="68"
          height="32"
          rx="4"
          fill="white"
          stroke="#F5E6F5"
          strokeWidth="4"
        />
        <rect
          x="444"
          y="320"
          width="68"
          height="32"
          rx="4"
          fill="white"
          stroke="#F5E6F5"
          strokeWidth="4"
        />
        <rect
          x="-2"
          y="2"
          width="68"
          height="32"
          rx="4"
          transform="matrix(-1 0 0 1 771 212)"
          fill="white"
          stroke="#F5E6F5"
          strokeWidth="4"
        />
        <rect
          x="488"
          y="364"
          width="68"
          height="32"
          rx="4"
          fill="white"
          stroke="#F5E6F5"
          strokeWidth="4"
        />
        <rect
          x="-2"
          y="2"
          width="68"
          height="32"
          rx="4"
          transform="matrix(-1 0 0 1 727 256)"
          fill="white"
          stroke="#F5E6F5"
          strokeWidth="4"
        />
        <rect
          x="355"
          y="496"
          width="68"
          height="32"
          rx="4"
          fill="white"
          stroke="#F5E6F5"
          strokeWidth="4"
        />
        <rect
          x="148"
          y="242"
          width="68"
          height="32"
          rx="4"
          fill="white"
          stroke="#F5E6F5"
          strokeWidth="4"
        />
        <rect
          x="777"
          y="452"
          width="68"
          height="32"
          rx="4"
          fill="white"
          stroke="#F5E6F5"
          strokeWidth="4"
        />
        <rect
          x="398"
          y="540"
          width="68"
          height="32"
          rx="4"
          fill="white"
          stroke="#F5E6F5"
          strokeWidth="4"
        />
        <g filter="url(#filter0_dd_2654_2615)">
          <rect x="50" y="425" width="282" height="192" rx="12" fill="white" />
          <rect
            x="50"
            y="425"
            width="282"
            height="192"
            rx="12"
            stroke="#990099"
            strokeWidth="4"
          />
        </g>
        <path
          d="M73 617H98V628C98 631.314 95.3137 634 92 634H79C75.6863 634 73 631.314 73 628V617Z"
          fill="#F5E6F5"
          stroke="#990099"
          strokeWidth="4"
        />
        <path
          d="M287 617H312V628C312 631.314 309.314 634 306 634H293C289.686 634 287 631.314 287 628V617Z"
          fill="#F5E6F5"
          stroke="#990099"
          strokeWidth="4"
        />
        <circle
          cx="141"
          cy="521"
          r="61"
          fill="#EBCCEB"
          stroke="#990099"
          strokeWidth="4"
        />
        <g id="blades" className={styles.blades}>
          <path
            d="M125 488.215C125 500.709 131.455 508.919 134.85 511.477C135.328 511.838 135.957 511.875 136.516 511.66L142.552 509.339C143.037 509.152 143.434 508.771 143.66 508.303C150.878 493.339 158.212 496.735 172.783 496.208C174.265 496.154 175.106 494.534 174.271 493.309C156.802 467.652 125 471.845 125 488.215Z"
            fill="white"
            stroke="#990099"
            strokeWidth="3.5"
          />
          <path
            d="M178.227 525.73C167.523 518.706 157.848 519.881 154.042 521.376C153.57 521.561 153.226 521.962 153.054 522.439L150.794 528.672C150.585 529.25 150.662 529.902 150.978 530.429C159.538 544.733 152.608 549.035 145.01 561.595C144.244 562.862 145.133 564.451 146.614 564.43C178.029 563.988 192.092 534.83 178.227 525.73Z"
            fill="white"
            stroke="#990099"
            strokeWidth="3.5"
          />
          <path
            d="M120.493 550.806C132.202 544.3 135.741 534.915 135.938 531.182C135.947 531.011 135.925 530.843 135.883 530.677L135.098 527.533C134.948 526.934 134.529 526.438 133.964 526.188L130.424 524.627C130.124 524.495 129.783 524.44 129.455 524.461C112.349 525.585 111.817 517.414 104.148 504.736C103.381 503.469 101.561 503.519 100.892 504.841C86.6998 532.87 105.997 558.862 120.493 550.806Z"
            fill="white"
            stroke="#990099"
            strokeWidth="3.5"
          />
          <circle
            cx="141"
            cy="521"
            r="12"
            fill="white"
            stroke="#990099"
            strokeWidth="3.5"
          />
        </g>
        <rect
          x="234"
          y="467"
          width="60"
          height="36"
          rx="6"
          fill="#EBCCEB"
          stroke="#990099"
          strokeWidth="4"
        />
        <path
          d="M236 527L292 527"
          stroke="#990099"
          strokeWidth="4"
          strokeLinecap="round"
        />
        <path
          d="M236 551L292 551"
          stroke="#990099"
          strokeWidth="4"
          strokeLinecap="round"
        />
        <path
          d="M236 575L292 575"
          stroke="#990099"
          strokeWidth="4"
          strokeLinecap="round"
        />
        <defs>
          <filter
            id="filter0_dd_2654_2615"
            x="0"
            y="391"
            width="382"
            height="292"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="16" />
            <feGaussianBlur stdDeviation="24" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_2654_2615"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
            />
            <feBlend
              mode="normal"
              in2="effect1_dropShadow_2654_2615"
              result="effect2_dropShadow_2654_2615"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect2_dropShadow_2654_2615"
              result="shape"
            />
          </filter>
          <linearGradient
            id="paint0_linear_2654_2615"
            x1="1697.35"
            y1="45.1104"
            x2="-25.9195"
            y2="875.426"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#990099" stopOpacity="0.1" />
            <stop offset="1" stopColor="#990099" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};

export default HeatProductPageHeatPumpImage;
