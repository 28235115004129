import {
  toMediaQueries,
  toVariables,
} from '@uikit/helpers/responsiveProperties';
import clsx from 'clsx';
import Image from 'next/image';
import {
  createImageURL,
  ratioVariantToRect,
} from '../LegacyPicture/image-transformer/helpers';

import styles from './Picture.module.scss';
import type { PictureProps } from './types';

const MIN_PICTURE_WIDTH = 375;
const PIXEL_SURROGATE_05 =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP88/n7fwAJwAPn/Xt8awAAAABJRU5ErkJggg==';

const Picture = ({
  asset,
  quality = 75,
  aspectRatio,
  fill,
  priority,
  objectFit,
  objectPosition,
  className,
}: PictureProps) => {
  const { path, name, rects, metadata, dimensions } = asset;
  const { width, height } = dimensions || {
    width: MIN_PICTURE_WIDTH,
    height: 0,
  };

  const alt = metadata?.caption ?? metadata?.title ?? name;
  const title = metadata?.title ?? name;

  const fillProps = fill ? { fill: true } : { width, height };

  const vars = {
    objectFit,
    objectPosition,
    aspectRatio,
  };

  const imageAspectRatio =
    typeof aspectRatio === 'string' ? aspectRatio : undefined;

  return (
    <Image
      priority={priority}
      src={path}
      className={clsx(styles.image, className)}
      quality={quality}
      loader={({ src, width, quality }) =>
        createImageURL(src, {
          w: width,
          q: quality,
          rect:
            imageAspectRatio && ratioVariantToRect(rects?.[imageAspectRatio]),
        })
      }
      placeholder="blur"
      blurDataURL={asset.placeholderBase64 || PIXEL_SURROGATE_05}
      data-responsive-breakpoints={toMediaQueries(vars)}
      style={{
        ...toVariables(vars),
      }}
      {...{ alt, title, ...fillProps }}
    />
  );
};

export default Picture;
