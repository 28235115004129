import React from 'react';
import Icon from '../Icon/Icon';
import type { MeinHGasNavLinkProps } from './interfaces';
import styles from './MeinHGasNavLink.module.scss';

const MeinHGasNavLink = ({ url, label }: MeinHGasNavLinkProps) => {
  return (
    <div className={styles.base}>
      <a href={url}>
        <div className={styles.label}>
          <span>
            {label}
            <Icon
              variant={'action/forward'}
              size="iconSize20"
              className={styles.icon}
            />
          </span>
        </div>
      </a>
    </div>
  );
};

export default MeinHGasNavLink;
