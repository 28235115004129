import clsx from 'clsx';
import styles from './AdaptiveCardArrow.module.scss';
import { AdaptiveCardArrowProps } from './types';

const AdaptiveCardArrow = ({
  className,
  color = 'currentColor',
  inverted = false,
}: AdaptiveCardArrowProps) => {
  return (
    <svg
      className={clsx(styles.base, className)}
      fill="none"
      height="36"
      style={{ color }}
      viewBox="0 0 36 36"
      width="36"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        fill={inverted ? 'currentColor' : '#FFF'}
        cx="18"
        cy="18"
        r="16"
      />
      <path
        clipRule="evenodd"
        d="M18 36C27.9411 36 36 27.9411 36 18C36 8.05887 27.9411 0 18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36ZM16.0607 9.93934C15.4749 9.35355 14.5251 9.35355 13.9393 9.93934C13.3536 10.5251 13.3536 11.4749 13.9393 12.0607L19.8787 18L13.9393 23.9393C13.3536 24.5251 13.3536 25.4749 13.9393 26.0607C14.5251 26.6464 15.4749 26.6464 16.0607 26.0607L23.0607 19.0607C23.6464 18.4749 23.6464 17.5251 23.0607 16.9393L16.0607 9.93934Z"
        fillRule="evenodd"
        fill={inverted ? '#FFF' : 'currentColor'}
      />
    </svg>
  );
};

export default AdaptiveCardArrow;
