import { currentStage } from '@/config';
import { logger } from '@/helpers/logger';
import React from 'react';

const DevErrorMessage = ({ children }: { children: React.ReactNode }) => {
  logger.error(children);

  return currentStage === 'prod' ? null : (
    <pre
      style={{
        color: 'red',
        fontWeight: 'bold',
        textAlign: 'center',
        border: '4px double red',
      }}
    >
      {children}
    </pre>
  );
};

export default DevErrorMessage;
