import styles from './AdaptiveCardHeadline.module.scss';
import { AdaptiveCardHeadlineProps } from './types';

const AdaptiveCardHeadline = ({
  className,
  subtitle,
  title,
}: AdaptiveCardHeadlineProps) => {
  return (
    <div className={className}>
      <h3 className={styles.subtitle}>{subtitle}</h3>
      <h2 className={styles.title}>{title}</h2>
    </div>
  );
};

export default AdaptiveCardHeadline;
