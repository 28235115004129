import clsx from 'clsx';
import Paper from '../Paper/Paper';
import styles from './AdaptiveCard.module.scss';
import AdaptiveCardArrow from './AdaptiveCardArrow';
import AdaptiveCardContent from './AdaptiveCardContent';
import AdaptiveCardHeadline from './AdaptiveCardHeadline';
import { AdaptiveCardProps } from './types';

const AdaptiveCard = ({
  backgroundLayer,
  children,
  className,
}: AdaptiveCardProps) => {
  return (
    <Paper
      className={clsx(styles.base, className)}
      borderRadius={1}
      elevation={2}
    >
      {backgroundLayer && (
        <div className={styles.backgroundLayer}>{backgroundLayer}</div>
      )}
      <div className={styles.surfaceLayer}>{children}</div>
    </Paper>
  );
};

AdaptiveCard.Arrow = AdaptiveCardArrow;
AdaptiveCard.Content = AdaptiveCardContent;
AdaptiveCard.Headline = AdaptiveCardHeadline;

export default AdaptiveCard;
