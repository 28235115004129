import { logger } from '@/helpers/logger';
import React, { ReactNode, useContext } from 'react';
import { PageContentItem } from '../../models/page';
import { CmsComponentsContext } from '../../page-templates/CmsComponentsContext';
import DevErrorMessage from '../DevErrorMessage/DevErrorMessage';
import {
  CONTENTS_AS_PROP_COMPONENT_WHITELIST,
  IGNORED_COMPONENT_NAMES,
} from './consts';
import type { ItemToComponentProps } from './interfaces';

const ItemToComponent = ({ item }: ItemToComponentProps): JSX.Element => {
  const cmsComponents = useContext(CmsComponentsContext);
  if (!cmsComponents) {
    throw new Error('invalid cms components');
  }

  const Comp = item.componentName.startsWith('contents-')
    ? React.Fragment
    : cmsComponents[item.componentName];

  if (IGNORED_COMPONENT_NAMES.includes(item.componentName)) {
    return <React.Fragment />;
  }

  if (typeof Comp === 'undefined') {
    logger.error(`No component with name: ${item.componentName} - `);
    return (
      <DevErrorMessage>
        No component with name: {item.componentName}
      </DevErrorMessage>
    );
  }

  let children: PageContentItem[] | ReactNode = null;
  const childrenArray: PageContentItem[] = [];
  let shouldIterateChildren = false;

  if (item.contents?.length) {
    // check if contents contains more contents areas (for components with multiple areas)
    item.contents.forEach((contentsItem) => {
      if (
        contentsItem.componentName.startsWith('contents-') &&
        typeof item.props == 'object'
      ) {
        // @ts-ignore
        item.props[contentsItem.componentName] = (
          <ItemToComponent item={contentsItem} />
        );
      } else {
        childrenArray.push(contentsItem);
      }
    });
    children = childrenArray;
    shouldIterateChildren = true;

    // show warning if contents AND children exist
    if (item.props?.children) {
      logger.warn(
        `Component ${item.componentName} has contents AND children. Something is wrong!`
      );
    }
  } else if (item.props) {
    children = item.props.children;
  }

  if (
    CONTENTS_AS_PROP_COMPONENT_WHITELIST.some((value) =>
      typeof value === 'string'
        ? value === item.componentName
        : value.test(item.componentName)
    )
  ) {
    shouldIterateChildren = false;
    if (item.props) {
      item.props = { ...item.props, contents: item.contents };
    }
  }

  const props =
    React.Fragment === Comp || !('props' in item)
      ? undefined
      : Object.entries(item?.props).reduce<any>((result, [key, value]) => {
          if (key.startsWith('jcr:')) {
            return result;
          }
          result[key] = value;
          return result;
        }, {});

  return (
    <Comp {...props}>
      {shouldIterateChildren
        ? (children as PageContentItem[]).map(
            (child): JSX.Element => (
              <ItemToComponent key={child.id} item={child} />
            )
          )
        : children}
    </Comp>
  );
};

export default ItemToComponent;
