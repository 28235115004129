import { useHover } from '@/contexts/Hover/Hover.context';
import clsx from 'clsx';
import { AnimationItem } from 'lottie-web';
import { useEffect, useState } from 'react';
import Lottie from '../Lottie/Lottie';
import { EnterFrameCallbackEvent } from '../Lottie/interfaces';
import styles from './ProductPageLottie.module.scss';
import type { ProductPageLottieProps } from './types';

const ProductPageLottie = ({
  className,
  loopStart,
  loopEnd,
  animationData,
  id,
}: ProductPageLottieProps) => {
  const isPlaying = useHover();
  const [isHoverPlaying, setIsHoverPlaying] = useState(false);

  const handleEnterFrame = (
    $event: EnterFrameCallbackEvent,
    animationItem: AnimationItem
  ) => {
    if (!isHoverPlaying) return;

    if (Math.round($event.currentTime) >= loopEnd) {
      animationItem.goToAndPlay(loopStart, true);
    }
  };

  useEffect(() => {
    setIsHoverPlaying(isPlaying);
  }, [isPlaying]);

  return (
    <span
      onMouseEnter={() => setIsHoverPlaying(true)}
      onMouseLeave={() => setIsHoverPlaying(false)}
      data-playing={isHoverPlaying}
      className={clsx(styles.base, className)}
    >
      <Lottie
        name={id}
        animationData={animationData}
        onEnterFrame={handleEnterFrame}
        loop
        playing={isHoverPlaying}
        pauseMode="finishAndPause"
      />
    </span>
  );
};

export default ProductPageLottie;
