import { extractClickableProps } from '@uikit/helpers/extractClickableProps';
import { useState } from 'react';
import Button from '../Button/Button';
import { ButtonSize } from '../Button/consts';
import MainHeader from '../NewHeader/MainHeader/MainHeader';
import styles from './LandingPageHeader.module.scss';
import { LandingPageHeaderProps } from './types';

const LandingPageHeader = ({
  ctaText,
  logo,
  items,
  ...props
}: LandingPageHeaderProps) => {
  const clickableProps = extractClickableProps(props);

  const pageHeaderButton = ctaText ? (
    <Button
      {...clickableProps}
      clickContext="Landing Page Header"
      size={ButtonSize.Small}
    >
      {ctaText}
    </Button>
  ) : null;

  const [isCollapsibleActive, setIsCollapsibleActive] = useState(false);

  const handleOnCollapsibleToggle = () =>
    setIsCollapsibleActive((value) => !value);

  return (
    <div className={styles.base}>
      <MainHeader
        hasActiveCollapsible={isCollapsibleActive}
        isFullWidth={false}
        items={items ?? []}
        layout="default"
        logoSlot={logo}
        onCollapsibleToggle={handleOnCollapsibleToggle}
        renderAddons={() => pageHeaderButton}
        shouldBeSticky
      />
    </div>
  );
};

export default LandingPageHeader;
