import React, { PropsWithChildren, useContext, useState } from 'react';
import type { HoverContextValue, HoverProviderProps } from './types';

const initialValue: HoverContextValue = {
  isHovering: false,
};

const HoverContext = React.createContext<HoverContextValue>(initialValue);

export const HoverProvider = ({
  children,
  className,
}: PropsWithChildren<HoverProviderProps>) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const { Provider } = HoverContext;

  return (
    <Provider
      value={{
        isHovering: isHovered,
      }}
    >
      <div
        className={className}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {children}
      </div>
    </Provider>
  );
};

export const useHover = () => {
  const context = useContext(HoverContext);

  if (context === undefined) {
    throw new Error('useHover must be used within an HoverContext provider');
  }

  return context.isHovering;
};

export default HoverContext;
