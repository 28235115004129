import clsx from 'clsx';
import styles from './AdaptiveCardContent.module.scss';
import { AdaptiveCardContentProps } from './types';

const AdaptiveCardContent = ({
  alignment,
  children,
  className,
}: AdaptiveCardContentProps) => {
  return (
    <div className={clsx(styles.base, styles[alignment], className)}>
      {children}
    </div>
  );
};

export default AdaptiveCardContent;
