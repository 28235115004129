import { getSafeInnerHTMLProp } from '@/helpers/getSafeInnerHTML';
import useNoticeBar from '@/hooks/useNoticeBar/useNoticeBar';
import { extractClickableProps } from '@uikit/helpers/extractClickableProps';
import clsx from 'clsx';
import parseCustomMarkdown from '../../helpers/parseCustomMarkdown';
import Clickable from '../Clickable/Clickable';
import styles from './NoticeBar.module.scss';
import type { NoticeBarProps } from './interfaces';

const NoticeBar = ({
  linkText,
  notice,
  variant = 'default',
  ...props
}: NoticeBarProps) => {
  const clickableProps = extractClickableProps(props);
  const { showNoticeBar } = useNoticeBar();

  return (
    <div
      className={clsx(styles.bar, styles[variant], {
        [styles.isHidden]: !showNoticeBar,
      })}
    >
      <div className={clsx(styles.container, props.className)} data-nosnippet>
        <Clickable
          className={styles.link}
          clickContext="Störer"
          asATag
          {...clickableProps}
          customDataLayer={{
            ...clickableProps.customDataLayer,
            customClickText: notice,
          }}
        >
          <span
            className={styles.notice}
            {...getSafeInnerHTMLProp(
              parseCustomMarkdown(`${notice} ~${linkText}~`)
            )}
          />
        </Clickable>
      </div>
    </div>
  );
};

export default NoticeBar;
