import { BreakPoint } from '@/hooks/useBreakPoint/consts';
import useDataLayer from '@/hooks/useDataLayer/useDataLayer';
import { HeaderLink } from '@/hooks/useHeader/types';
import useIsMobile from '@/hooks/useIsMobile/useIsMobile';
import clsx from 'clsx';
import { useEffect } from 'react';
import { extractClickableProps } from '../../helpers/extractClickableProps';
import Button from '../Button/Button';
import { ButtonColor, ButtonSize, ShowAddon, ShowIcon } from '../Button/consts';
import { ClickableAction } from '../Clickable/consts';
import LandingPageHeader from '../LandingPageHeader/LandingPageHeader';
import MeinHGasNavLink from '../MeinHGasNavLink/MeinHGasNavLink';
import NewHeader from '../NewHeader/NewHeader';
import PageHeader from '../NewHeader/PageHeader/PageHeader';
import { HEADER_LOGO_HEIGHTS } from '../NewHeader/consts';
import { NewHeaderProps } from '../NewHeader/types';
import ProjectLogo from '../ProjectLogo/ProjectLogo';
import styles from './LegacyHeader.module.scss';
import { LegacyHeaderVariant } from './consts';
import {
  convertProjectName,
  convertVariant,
  createPageHeaderLink,
} from './helpers';
import { LegacyHeaderProps } from './types';

const LegacyHeader = ({
  contents,
  ctaText,
  headerTitle,
  headerTitleLink,
  hideGlobalHeader,
  icon,
  invert = false,
  isFullWidth = false,
  isPageHeaderVisible,
  logo,
  logoLink = '/',
  navItems,
  noBorderBottom,
  noSticky,
  pageHeaderLogo,
  pageHeaderLogoLink,
  pageHeaderType,
  pageNavItemMain,
  parentPagePath,
  showIcon,
  siteVersion = 2,
  variant = LegacyHeaderVariant.Privatkunde,
  ...props
}: LegacyHeaderProps) => {
  const { pushDataLayerEvent } = useDataLayer();
  const isMobile = useIsMobile(BreakPoint.LG);

  const clickableProps = extractClickableProps(props);

  const fallbackLogo = (
    <ProjectLogo
      height={
        HEADER_LOGO_HEIGHTS[convertProjectName(variant)][
          isMobile ? 'mobile' : 'desktop'
        ]
      }
      project={convertProjectName(variant)}
    />
  );

  const pageHeaderLinks = contents?.length
    ? contents
        .filter(
          ({ componentName, props }) =>
            componentName.startsWith('navItems') &&
            props.link in props &&
            props[props.link as keyof typeof props]
        )
        .map<HeaderLink>(createPageHeaderLink)
    : navItems?.map<HeaderLink>((item) => ({
        isInternalUrl: true,
        title: item.title,
        url: item.page,
      }));

  const pageHeaderButton = !ctaText ? undefined : (
    <Button
      {...clickableProps}
      clickContext="Page Header"
      icon={icon}
      showIcon={showIcon}
      size={ButtonSize.Small}
    >
      {ctaText}
    </Button>
  );

  const convertedVariant = convertVariant(variant);

  const convertedProps: NewHeaderProps = {
    hasPageHeader:
      isPageHeaderVisible ?? (!!pageHeaderLinks?.length || !!headerTitle),
    hasPreHeader: [
      'enercity',
      'enercity-com',
      'enercity-gwk',
      'ecg',
      'commune',
    ].includes(convertedVariant),
    isFullWidth: isFullWidth,
    isInverted: invert,
    isSticky: !noSticky,
    renderMainHeaderAddons: ['eng', 'mein-h-gas'].includes(convertedVariant)
      ? ({ shouldHideContent }) => (
          <div
            className={clsx(styles.addonWrapper, {
              [styles.isHidden]: shouldHideContent,
            })}
          >
            {convertedVariant === 'eng' && (
              <Button
                actionType={ClickableAction.Linkintern}
                color={ButtonColor.Secondary}
                icon={'user/electric-meter'}
                linkintern="/zaehler/zaehlerstand"
                showAddon={ShowAddon.Gradient}
                showIcon={ShowIcon.ShowIcon}
              >
                Zählerstand melden
              </Button>
            )}

            {convertedVariant === 'mein-h-gas' && (
              <MeinHGasNavLink
                label="Vertragsinstallateure & Schornsteinfeger"
                url="/vertragsinstallateure-schornsteinfeger"
              />
            )}
          </div>
        )
      : undefined,
    renderPageHeader: (props) => (
      <PageHeader
        {...props}
        addonsSlot={!!pageHeaderButton && <>{pageHeaderButton}</>}
        links={pageHeaderLinks}
        overviewUrl={headerTitleLink ?? parentPagePath}
        title={headerTitle}
      />
    ),
    variant: convertedVariant,
  };

  useEffect(() => {
    if (!hideGlobalHeader) {
      pushDataLayerEvent({ siteVersion });
    }
  }, [hideGlobalHeader, pushDataLayerEvent, siteVersion]);

  return hideGlobalHeader ? (
    <LandingPageHeader
      {...props}
      ctaText={ctaText}
      items={pageHeaderLinks}
      logo={logo ?? fallbackLogo}
    />
  ) : (
    <NewHeader {...convertedProps} />
  );
};

export default LegacyHeader;
