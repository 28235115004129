import { toVariables } from '@uikit/helpers/responsiveProperties';
import clsx from 'clsx';
import styles from './Paper.module.scss';
import type { PaperProps } from './interfaces';

const Paper = ({
  children,
  borderRadius,
  elevation = 0,
  className,
}: PaperProps) => {
  const vars = { borderRadius };

  const elevationStyles = {
    0: styles.default,
    1: styles.small,
    2: styles.medium,
    3: styles.large,
  };

  return (
    <div
      className={clsx(styles.base, elevationStyles[elevation], className)}
      style={{ ...toVariables(vars) }}
    >
      {children}
    </div>
  );
};

export default Paper;
