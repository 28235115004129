import NoticeBarContext from '@/contexts/NoticeBar/NoticeBar.context';
import React from 'react';

function useNoticeBar() {
  const context = React.useContext(NoticeBarContext);

  if (context === undefined) {
    throw new Error(
      'useNoticeBar must be used within a NoticeBarContext provider'
    );
  }

  return context;
}

export default useNoticeBar;
